import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// const endpoint = 'http://localhost:80/API_REST_CO/';
const  endpoint = 'https://clanbolivia.online/coperagro/API_REST_CO/';
const  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  usernameG = '';
  sesion = [];

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    const body = res;
    return body || { };
  }

  getUserDetails(username, password): Observable<any> {
    return this.http.post(endpoint + 'setLoginUsuarioSistema', {
      username,
      password
    }).pipe(
    map(this.extractData));
}
 guardarDatos(username) {
  this.usernameG = username;
}

recuperarDatos() {
  return this.usernameG;
}

guardarSesion(sesion) {
  this.sesion = sesion;
  sessionStorage.setItem('currentUser', JSON.stringify(sesion));
  // localStorage.clear();
}

recuperarSesion() {
  return JSON.parse(sessionStorage.getItem('currentUser'));
}

// GES08GA
setEliminarGastoApp(gasto): Observable<any> {
  return this.http.post(endpoint + 'setEliminarGastoApp',
    gasto
  ).pipe(
  map(this.extractData));
}

// GES01U
setRegistroTipoEnfermedad(tipoEnfermedad): Observable<any> {
  return this.http.post(endpoint + 'setRegistroTipoEnfermedad',
    tipoEnfermedad
  ).pipe(
  map(this.extractData));
}

// GES02U
setModificarTipoEnfermedad(tipoEnfermedad): Observable<any> {
  return this.http.post(endpoint + 'setModificarTipoEnfermedad',
    tipoEnfermedad
  ).pipe(
  map(this.extractData));
}

// GES03U
getListaTipoEnfermedad(): Observable<any> {
  return this.http.get(endpoint + 'getListaTipoEnfermedad').pipe(
  map(this.extractData));
}

// GES04U
setRegistroEnfermedad(enfermedad): Observable<any> {
  return this.http.post(endpoint + 'setRegistroEnfermedad',
    enfermedad
  ).pipe(
  map(this.extractData));
}

// GES05U
setModificarEnfermedad(enfermedad): Observable<any> {
  return this.http.post(endpoint + 'setModificarEnfermedad',
    enfermedad
  ).pipe(
  map(this.extractData));
}

// GES06U
getListaEnfermedad(): Observable<any> {
  return this.http.get(endpoint + 'getListaEnfermedad').pipe(
  map(this.extractData));
}

// GES07U
setRegistroPatogeno(patogeno): Observable<any> {
  return this.http.post(endpoint + 'setRegistroPatogeno',
    patogeno
  ).pipe(
  map(this.extractData));
}

// GES08U
setModificarPatogeno(patogeno): Observable<any> {
  return this.http.post(endpoint + 'setModificarPatogeno',
    patogeno
  ).pipe(
  map(this.extractData));
}

// GES09U
getListaPatogeno(): Observable<any> {
  return this.http.get(endpoint + 'getListaPatogeno').pipe(
  map(this.extractData));
}

// GES10U
setRegistroTipoPatogeno(tipoPatogeno): Observable<any> {
  return this.http.post(endpoint + 'setRegistroTipoPatogeno',
    tipoPatogeno
  ).pipe(
  map(this.extractData));
}

// GES11U
setModificarTipoPatogeno(tipoPatogeno): Observable<any> {
  return this.http.post(endpoint + 'setModificarTipoPatogeno',
    tipoPatogeno
  ).pipe(
  map(this.extractData));
}

// GES12U
getListaTipoPatogeno(): Observable<any> {
  return this.http.get(endpoint + 'getListaTipoPatogeno').pipe(
  map(this.extractData));
}

// GES13U
setRegistroEpidemiologia(epidemiologia): Observable<any> {
  return this.http.post(endpoint + 'setRegistroEpidemiologia',
    epidemiologia
  ).pipe(
  map(this.extractData));
}

// GES14U
setModificarEpidemiologia(epidemiologia): Observable<any> {
  return this.http.post(endpoint + 'setModificarEpidemiologia',
    epidemiologia
  ).pipe(
  map(this.extractData));
}

// GES15U
getListaEpidemiologia(): Observable<any> {
  return this.http.get(endpoint + 'getListaEpidemiologia').pipe(
  map(this.extractData));
}

// GES16U
setRegistroBiologiaPatogeno(patogeno): Observable<any> {
  return this.http.post(endpoint + 'setRegistroBiologiaPatogeno',
    patogeno
  ).pipe(
  map(this.extractData));
}

// GES17U
setModificarBiologiaPatogeno(patogeno): Observable<any> {
  return this.http.post(endpoint + 'setModificarBiologiaPatogeno',
    patogeno
  ).pipe(
  map(this.extractData));
}

// GES18U
getListaBiologiaPatogeno(): Observable<any> {
  return this.http.get(endpoint + 'getListaBiologiaPatogeno').pipe(
  map(this.extractData));
}

// GES19U
setRegistroFase(fase): Observable<any> {
  return this.http.post(endpoint + 'setRegistroFase',
    fase
  ).pipe(
  map(this.extractData));
}

// GES20U
setModificarFase(fase): Observable<any> {
  return this.http.post(endpoint + 'setModificarFase',
    fase
  ).pipe(
  map(this.extractData));
}

// GES21U
getListaFase(): Observable<any> {
  return this.http.get(endpoint + 'getListaFase').pipe(
  map(this.extractData));
}

// GES22U
setRegistroPatrocinador(patrocinador): Observable<any> {
  return this.http.post(endpoint + 'setRegistroPatrocinador',
    patrocinador
  ).pipe(
  map(this.extractData));
}

// GES23U
setModificarPatrocinador(patrocinador): Observable<any> {
  return this.http.post(endpoint + 'setModificarPatrocinador',
    patrocinador
  ).pipe(
  map(this.extractData));
}

// GES24U
getListaPatrocinador(): Observable<any> {
  return this.http.get(endpoint + 'getListaPatrocinador').pipe(
  map(this.extractData));
}

// GES25U
setRegistroCultivo(cultivo): Observable<any> {
  return this.http.post(endpoint + 'setRegistroCultivo',
    cultivo
  ).pipe(
  map(this.extractData));
}

// GES26U
setModificarCultivo(cultivo): Observable<any> {
  return this.http.post(endpoint + 'setModificarCultivo',
    cultivo
  ).pipe(
  map(this.extractData));
}

// GES27U
getListaCultivo(): Observable<any> {
  return this.http.get(endpoint + 'getListaCultivo').pipe(
  map(this.extractData));
}

// GES28U
setRegistroContacto(contacto): Observable<any> {
  return this.http.post(endpoint + 'setRegistroContacto',
    contacto
  ).pipe(
  map(this.extractData));
}

// GES29U
setModificarContacto(contacto): Observable<any> {
  return this.http.post(endpoint + 'setModificarContacto',
    contacto
  ).pipe(
  map(this.extractData));
}

// GES30U
getListaContacto(): Observable<any> {
  return this.http.get(endpoint + 'getListaContacto').pipe(
  map(this.extractData));
}

// GES34U
getListaFotografia(idEnfermedad): Observable<any> {
  return this.http.get(endpoint + 'getListaFotografia/' + idEnfermedad).pipe(
  map(this.extractData));
}

// GES35U
setRegistroFotografia(fotografia): Observable<any> {
  return this.http.post(endpoint + 'setRegistroFotografia',
    fotografia
  ).pipe(
  map(this.extractData));
}

// GES36U
setEliminarFotografia(fotografia): Observable<any> {
  return this.http.post(endpoint + 'setEliminarFotografia',
    fotografia
  ).pipe(
  map(this.extractData));
}

// GES37U
setRegistroProducto(producto): Observable<any> {
  return this.http.post(endpoint + 'setRegistroProducto',
    producto
  ).pipe(
  map(this.extractData));
}

// GES38U
setModificarProducto(producto): Observable<any> {
  return this.http.post(endpoint + 'setModificarProducto',
    producto
  ).pipe(
  map(this.extractData));
}

// GES39U
getListaProducto(): Observable<any> {
  return this.http.get(endpoint + 'getListaProducto').pipe(
  map(this.extractData));
}

// GES40U
setRegistroNoticia(noticia): Observable<any> {
  return this.http.post(endpoint + 'setRegistroNoticia',
    noticia
  ).pipe(
  map(this.extractData));
}

// GES41U
setModificarNoticia(noticia): Observable<any> {
  return this.http.post(endpoint + 'setModificarNoticia',
    noticia
  ).pipe(
  map(this.extractData));
}

// GES43U
setEliminarNoticia(noticia): Observable<any> {
  return this.http.post(endpoint + 'setEliminarNoticia',
    noticia
  ).pipe(
  map(this.extractData));
}

// GES42U
getListaNoticia(): Observable<any> {
  return this.http.get(endpoint + 'getListaNoticia').pipe(
  map(this.extractData));
}

// GES44U
setDesactivarEnfermedad(enfermedad): Observable<any> {
  return this.http.post(endpoint + 'setDesactivarEnfermedad',
    enfermedad
  ).pipe(
  map(this.extractData));
}

// GES45U
setCambiarFase(fotografia): Observable<any> {
  return this.http.post(endpoint + 'setCambiarFase',
    fotografia
  ).pipe(
  map(this.extractData));
}

// GES46U
setEliminarContacto(contacto): Observable<any> {
  return this.http.post(endpoint + 'setEliminarContacto',
    contacto
  ).pipe(
  map(this.extractData));
}

// GES47U
setEliminarProducto(producto): Observable<any> {
  return this.http.post(endpoint + 'setEliminarProducto',
    producto
  ).pipe(
  map(this.extractData));
}

// GES01S
setRegistroUsuarioSistema(usuario): Observable<any> {
  return this.http.post(endpoint + 'setRegistroUsuarioSistema',
    usuario
  ).pipe(
  map(this.extractData));
}

// GES02S
setModificarUsuarioSistema(usuario): Observable<any> {
  return this.http.post(endpoint + 'setModificarUsuarioSistema',
    usuario
  ).pipe(
  map(this.extractData));
}

// GES03S
getListaUsuarioSistema(): Observable<any> {
  return this.http.get(endpoint + 'getListaUsuarioSistema').pipe(
  map(this.extractData));
}

// GES06S
getListaTipoUsuario(): Observable<any> {
  return this.http.get(endpoint + 'getListaTipoUsuario').pipe(
  map(this.extractData));
}

// GES08S
setModificarUsuario2(usuario): Observable<any> {
  return this.http.post(endpoint + 'setModificarUsuario2',
    usuario
  ).pipe(
  map(this.extractData));
}

// GES01G
setRegistroUsuario(usuario): Observable<any> {
  return this.http.post(endpoint + 'setRegistroUsuario',
    usuario
  ).pipe(
  map(this.extractData));
}

// GES02G
setModificarUsuario(usuario): Observable<any> {
  return this.http.post(endpoint + 'setModificarUsuario',
    usuario
  ).pipe(
  map(this.extractData));
}

// GES03S
getListaUsuario(): Observable<any> {
  return this.http.get(endpoint + 'getListaUsuario').pipe(
  map(this.extractData));
}

// GES04G
setRegistroTipoGasto(tipoGasto): Observable<any> {
  return this.http.post(endpoint + 'setRegistroTipoGasto',
    tipoGasto
  ).pipe(
  map(this.extractData));
}

// GES05G
setModificarTipoGasto(tipoGasto): Observable<any> {
  return this.http.post(endpoint + 'setModificarTipoGasto',
    tipoGasto
  ).pipe(
  map(this.extractData));
}

// GES06S
getListaTipoGasto(): Observable<any> {
  return this.http.get(endpoint + 'getListaTipoGasto').pipe(
  map(this.extractData));
}

// GES07G
setRegistroProveedor(proveedor): Observable<any> {
  return this.http.post(endpoint + 'setRegistroProveedor',
    proveedor
  ).pipe(
  map(this.extractData));
}

// GES08G
setModificarProveedor(proveedor): Observable<any> {
  return this.http.post(endpoint + 'setModificarProveedor',
    proveedor
  ).pipe(
  map(this.extractData));
}

// GES09S
getListaProveedor(): Observable<any> {
  return this.http.get(endpoint + 'getListaProveedor').pipe(
  map(this.extractData));
}

// GES16G
setRegistroVehiculo(vehiculo): Observable<any> {
  return this.http.post(endpoint + 'setRegistroVehiculo',
    vehiculo
  ).pipe(
  map(this.extractData));
}

// GES17G
setModificarVehiculo(vehiculo): Observable<any> {
  return this.http.post(endpoint + 'setModificarVehiculo',
    vehiculo
  ).pipe(
  map(this.extractData));
}

// GES18S
getListaVehiculo(): Observable<any> {
  return this.http.get(endpoint + 'getListaVehiculo').pipe(
  map(this.extractData));
}

// GES19G
setRegistroAsignacionVehiculo(vehiculo): Observable<any> {
  return this.http.post(endpoint + 'setRegistroAsignacionVehiculo',
    vehiculo
  ).pipe(
  map(this.extractData));
}

// GES20G
setModificarAsignacionVehiculo(vehiculo): Observable<any> {
  return this.http.post(endpoint + 'setModificarAsignacionVehiculo',
    vehiculo
  ).pipe(
  map(this.extractData));
}

// GES21G
getListaAsignacionVehiculo(): Observable<any> {
  return this.http.get(endpoint + 'getListaAsignacionVehiculo').pipe(
  map(this.extractData));
}

// GES22S
getFechaFinAsignacion(id): Observable<any> {
  return this.http.get(endpoint + 'getFechaFinAsignacion/' + id).pipe(
  map(this.extractData));
}

// GES23G
getHistorialAsignacionVehiculo(id): Observable<any> {
  return this.http.get(endpoint + 'getHistorialAsignacionVehiculo/' + id).pipe(
  map(this.extractData));
}

// GES24G
getNombresUsuario(): Observable<any> {
  return this.http.get(endpoint + 'getNombresUsuario').pipe(
  map(this.extractData));
}

// GES25G
getDatosUsuario(id): Observable<any> {
  return this.http.get(endpoint + 'getDatosUsuario/' + id).pipe(
  map(this.extractData));
}

// GES26G
getFacturas(id, fechaI, fechaF): Observable<any> {
  return this.http.get(endpoint + 'getFacturas/' + id + '/' + fechaI + '/' + fechaF).pipe(
  map(this.extractData));
}

// GES27G
getTotalFacturas(id, fechaI, fechaF): Observable<any> {
  return this.http.get(endpoint + 'getTotalFacturas/' + id + '/' + fechaI + '/' + fechaF).pipe(
  map(this.extractData));
}

// GES28G
getTotal(id, fechaI, fechaF): Observable<any> {
  return this.http.get(endpoint + 'getTotal/' + id + '/' + fechaI + '/' + fechaF).pipe(
  map(this.extractData));
}

// GES29G
getRecibos(id, fechaI, fechaF): Observable<any> {
  return this.http.get(endpoint + 'getRecibos/' + id + '/' + fechaI + '/' + fechaF).pipe(
  map(this.extractData));
}

// GES30G
getTotalRecibos(id, fechaI, fechaF): Observable<any> {
  return this.http.get(endpoint + 'getTotalRecibos/' + id + '/' + fechaI + '/' + fechaF).pipe(
  map(this.extractData));
}

// GES31G
getDatosVehiculo(id, fechaI, fechaF): Observable<any> {
  return this.http.get(endpoint + 'getDatosVehiculo/' + id + '/' + fechaI + '/' + fechaF).pipe(
  map(this.extractData));
}

// GES32G
getControl(id, fechaI, fechaF): Observable<any> {
  return this.http.get(endpoint + 'getControl/' + id + '/' + fechaI + '/' + fechaF).pipe(
  map(this.extractData));
}

// GES33G
getComprobantes(id, fechaI, fechaF): Observable<any> {
  return this.http.get(endpoint + 'getComprobantes/' + id + '/' + fechaI + '/' + fechaF).pipe(
  map(this.extractData));
}

// GES34G
setDesactivarGasto(gasto): Observable<any> {
  return this.http.post(endpoint + 'setDesactivarGasto',
    gasto
  ).pipe(
  map(this.extractData));
}

// GES35G
setGuardarObservacion(gasto): Observable<any> {
  return this.http.post(endpoint + 'setGuardarObservacion',
    gasto
  ).pipe(
  map(this.extractData));
}

// GES36G
getDatosVehiculoPrestado(id, fechaI, fechaF): Observable<any> {
  return this.http.get(endpoint + 'getDatosVehiculoPrestado/' + id + '/' + fechaI + '/' + fechaF).pipe(
  map(this.extractData));
}


// GES01U
saveUser(usuario): Observable<any> {
  return this.http.post(endpoint + 'setRegistroUsuario',
    usuario
  ).pipe(
  map(this.extractData));
}

// GES03U
getUsuarios(): Observable<any> {
  return this.http.get(endpoint + 'getListaUsuario', {
  }).pipe(
  map(this.extractData));
}

// GES04U
getUsuario(id): Observable<any> {
  return this.http.get(endpoint + 'getUsuario/' + id).pipe(
    map(this.extractData));
}

// GES05U
modifyUser(usuario): Observable<any> {
  return this.http.post(endpoint + 'setModificarUsuario',
    usuario
  ).pipe(
  map(this.extractData));
}

// GES06U
deleteUser(usuario): Observable<any> {
  return this.http.post(endpoint + 'setEliminarUsuario',
    usuario
  ).pipe(
  map(this.extractData));
}

// GES07U
getTipoUsuario(): Observable<any> {
  return this.http.get(endpoint + 'getTipoUsuario').pipe(
    map(this.extractData));
}

// GES08U
getEstados(): Observable<any> {
  return this.http.get(endpoint + 'getEstados').pipe(
    map(this.extractData));
}

// GES09U
getPermisoUsuario(idTipoUsuario): Observable<any> {
  return this.http.get(endpoint + 'getPermisoUsuario/' + idTipoUsuario).pipe(
    map(this.extractData));
}

// GES10U
getPermisoTipoUsuario(): Observable<any> {
  return this.http.get(endpoint + 'getPermisoTipoUsuario').pipe(
    map(this.extractData));
}

// GES11U
getListaPermisos(): Observable<any> {
  return this.http.get(endpoint + 'getListaPermisos').pipe(
    map(this.extractData));
}

// GES12U
setRegistroTipoUsuario(tipoUsuario): Observable<any> {
  return this.http.post(endpoint + 'setRegistroTipoUsuario',
    tipoUsuario
  ).pipe(
  map(this.extractData));
}

// GES13U
setRegistroPermisosTipoUsuario(permiso): Observable<any> {
  return this.http.post(endpoint + 'setRegistroPermisosTipoUsuario',
    permiso
  ).pipe(
  map(this.extractData));
}

// GES14U
setModificarTipoUsuario(tipoUsuario): Observable<any> {
  return this.http.post(endpoint + 'setModificarTipoUsuario',
    tipoUsuario
  ).pipe(
  map(this.extractData));
}

// GES15U
setModificarPermisosTipoUsuario(permiso): Observable<any> {
  return this.http.post(endpoint + 'setModificarPermisosTipoUsuario',
    permiso
  ).pipe(
  map(this.extractData));
}

// GES16U
setDesactivarTipoUsuario(tipoUsuario): Observable<any> {
  return this.http.post(endpoint + 'setDesactivarTipoUsuario',
    tipoUsuario
  ).pipe(
  map(this.extractData));
}

// GES16U
getListaTipoSucursal(): Observable<any> {
  return this.http.get(endpoint + 'getListaTipoSucursal').pipe(
  map(this.extractData));
}

// GES18U
setRegistroTipoSucursal(tipoSucursal): Observable<any> {
  return this.http.post(endpoint + 'setRegistroTipoSucursal',
    tipoSucursal
  ).pipe(
  map(this.extractData));
}

// GES19U
setModificarTipoSucursal(tipoSucursal): Observable<any> {
  return this.http.post(endpoint + 'setModificarTipoSucursal',
    tipoSucursal
  ).pipe(
  map(this.extractData));
}

// GES20U
getListaSucursal(): Observable<any> {
  return this.http.get(endpoint + 'getListaSucursal').pipe(
  map(this.extractData));
}

// GES21U
setRegistroSucursal(sucursal): Observable<any> {
  return this.http.post(endpoint + 'setRegistroSucursal',
    sucursal
  ).pipe(
  map(this.extractData));
}

// GES22U
setModificarSucursal(sucursal): Observable<any> {
  return this.http.post(endpoint + 'setModificarSucursal',
    sucursal
  ).pipe(
  map(this.extractData));
}

// GES23U
setDesactivarSucursal(sucursal): Observable<any> {
  return this.http.post(endpoint + 'setDesactivarSucursal',
    sucursal
  ).pipe(
  map(this.extractData));
}

// GES27U
setDesactivarProducto(producto): Observable<any> {
  return this.http.post(endpoint + 'setDesactivarProducto',
    producto
  ).pipe(
  map(this.extractData));
}

// GES28U
getListaProductoSucursal(): Observable<any> {
  return this.http.get(endpoint + 'getListaProductoSucursal').pipe(
  map(this.extractData));
}

// GES28U
setRegistroProductoSucursal(producto): Observable<any> {
  return this.http.post(endpoint + 'setRegistroProductoSucursal',
    producto
  ).pipe(
  map(this.extractData));
}

// GES29U
getListaSucursalA(idProducto): Observable<any> {
  return this.http.get(endpoint + 'getListaSucursalA/' + idProducto).pipe(
    map(this.extractData));
}

// GES30U
getListaSucursalB(idSucursal): Observable<any> {
  return this.http.get(endpoint + 'getListaSucursalB/' + idSucursal).pipe(
    map(this.extractData));
}

// GES31U
setRegistroMovimientoProducto(movimientoProducto): Observable<any> {
  return this.http.post(endpoint + 'setRegistroMovimientoProducto',
  movimientoProducto
  ).pipe(
  map(this.extractData));
}

// GES32U
getListaMovimientoProducto(): Observable<any> {
  return this.http.get(endpoint + 'getListaMovimientoProducto').pipe(
  map(this.extractData));
}

// GES36U
setDesactivarProveedor(proveedor): Observable<any> {
  return this.http.post(endpoint + 'setDesactivarProveedor',
    proveedor
  ).pipe(
  map(this.extractData));
}

// GES37U
getListaProveedorA(): Observable<any> {
  return this.http.get(endpoint + 'getListaProveedorA').pipe(
  map(this.extractData));
}

// GES38U
getListaSucursalAc(): Observable<any> {
  return this.http.get(endpoint + 'getListaSucursalAc').pipe(
  map(this.extractData));
}

// GES39U
getListaProductoC(): Observable<any> {
  return this.http.get(endpoint + 'getListaProductoC').pipe(
  map(this.extractData));
}

// GES40U
getNombreProducto(idProducto): Observable<any> {
  return this.http.get(endpoint + 'getNombreProductoCo/' + idProducto).pipe(
    map(this.extractData));
}

// GES41U
setRegistroCompra(compra): Observable<any> {
  return this.http.post(endpoint + 'setRegistroCompra',
  compra
  ).pipe(
  map(this.extractData));
}

// GES42U
setRegistroDetalleCompra(detalles): Observable<any> {
  return this.http.post(endpoint + 'setRegistroDetalleCompra',
  detalles
  ).pipe(
  map(this.extractData));
}

// GES43U
getListaEgreso(): Observable<any> {
  return this.http.get(endpoint + 'getListaEgreso').pipe(
  map(this.extractData));
}

// GES44U
setRegistroEgreso(egreso): Observable<any> {
  return this.http.post(endpoint + 'setRegistroEgreso',
  egreso
  ).pipe(
  map(this.extractData));
}

// GES45U
getListaCliente(): Observable<any> {
  return this.http.get(endpoint + 'getListaCliente').pipe(
  map(this.extractData));
}

// GES46U
setRegistroCliente(cliente): Observable<any> {
  return this.http.post(endpoint + 'setRegistroCliente',
  cliente
  ).pipe(
  map(this.extractData));
}

// GES47U
setModificarCliente(cliente): Observable<any> {
  return this.http.post(endpoint + 'setModificarCliente',
  cliente
  ).pipe(
  map(this.extractData));
}

// GES48U
setDesactivarCliente(cliente): Observable<any> {
  return this.http.post(endpoint + 'setDesactivarCliente',
  cliente
  ).pipe(
  map(this.extractData));
}

// GES49U
getListaClienteA(): Observable<any> {
  return this.http.get(endpoint + 'getListaClienteA').pipe(
  map(this.extractData));
}

// GES50U
getListaComisionistaA(): Observable<any> {
  return this.http.get(endpoint + 'getListaComisionistaA').pipe(
  map(this.extractData));
}

// GES51U
getListaVendedorA(): Observable<any> {
  return this.http.get(endpoint + 'getListaVendedorA').pipe(
  map(this.extractData));
}

// GES52U
setRegistroVenta(venta): Observable<any> {
  return this.http.post(endpoint + 'setRegistroVenta',
  venta
  ).pipe(
  map(this.extractData));
}

// GES53U
setRegistroDetalleVenta(detalles): Observable<any> {
  return this.http.post(endpoint + 'setRegistroDetalleVenta',
  detalles
  ).pipe(
  map(this.extractData));
}

// GES54U
getListaProductoV(idSucursal): Observable<any> {
  return this.http.get(endpoint + 'getListaProductoV/' + idSucursal).pipe(
    map(this.extractData));
}


// GES56U
setModificarDatosUsuario(usuario): Observable<any> {
  return this.http.post(endpoint + 'setModificarDatosUsuario',
  usuario
  ).pipe(
  map(this.extractData));
}

// GES57U
getNumeroVentasMes(): Observable<any> {
  return this.http.get(endpoint + 'getNumeroVentasMes').pipe(
  map(this.extractData));
}

// GES58U
getNumeroComprasMes(): Observable<any> {
  return this.http.get(endpoint + 'getNumeroComprasMes').pipe(
  map(this.extractData));
}

// GES59U
getIngresosMes(): Observable<any> {
  return this.http.get(endpoint + 'getIngresosMes').pipe(
  map(this.extractData));
}

// GES60U
getEgresosMes(): Observable<any> {
  return this.http.get(endpoint + 'getEgresosMes').pipe(
  map(this.extractData));
}

// GES61U
getCantidadVentasDia(): Observable<any> {
  return this.http.get(endpoint + 'getCantidadVentasDia').pipe(
  map(this.extractData));
}

// GES62U
getEstadoFecha(fechaInicio, fechaFin): Observable<any> {
  return this.http.get(endpoint + 'getEstadoFecha/' + fechaInicio + '/' + fechaFin).pipe(
    map(this.extractData));
}

// GES63U
setRegistroProforma(proforma): Observable<any> {
  return this.http.post(endpoint + 'setRegistroProforma',
  proforma
  ).pipe(
  map(this.extractData));
}

// GES64U
setRegistroDetalleProforma(detalles): Observable<any> {
  return this.http.post(endpoint + 'setRegistroDetalleProforma',
  detalles
  ).pipe(
  map(this.extractData));
}

// GES65U
getListaSupervisorA(): Observable<any> {
  return this.http.get(endpoint + 'getListaSupervisorA').pipe(
  map(this.extractData));
}

// GES66U
setRegistroComisionProducto(comision): Observable<any> {
  return this.http.post(endpoint + 'setRegistroComisionProducto',
  comision
  ).pipe(
  map(this.extractData));
}

// GES67U
getListaComisionProducto(idProducto): Observable<any> {
  return this.http.get(endpoint + 'getListaComisionProducto/' + idProducto).pipe(
  map(this.extractData));
}

// GES68U
setModificarComisionProducto(comision): Observable<any> {
  return this.http.post(endpoint + 'setModificarComisionProducto',
    comision
  ).pipe(
  map(this.extractData));
}

// GES69U
setEliminarComisionProducto(comision): Observable<any> {
  return this.http.post(endpoint + 'setEliminarComisionProducto',
    comision
  ).pipe(
  map(this.extractData));
}

// GES70U
getVentaUsuario(idUsuario, fechaInicio, fechaFin): Observable<any> {
  return this.http.get(endpoint + 'getVentaUsuario/' + idUsuario + '/' + fechaInicio + '/' + fechaFin).pipe(
    map(this.extractData));
}

// GES71U
getComisionUsuario(idUsuario, fechaInicio, fechaFin): Observable<any> {
  return this.http.get(endpoint + 'getComisionUsuario/' + idUsuario + '/' + fechaInicio + '/' + fechaFin).pipe(
    map(this.extractData));
}

private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

}
