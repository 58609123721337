import { ApiService } from './../api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  sesion: any = {};

  constructor(private Api: ApiService, private router: Router) { }

  showNotification(from, align, mensaje, tipo) {
    const type = ['', 'info', 'success', 'warning', 'danger'];
    const color = 'green';
      $.notify({
        icon: 'notifications',
        message: mensaje
      }, {
        type: type[color],
        timer: 4000,
        placement: {
            from: from,
            align: align
        },
        template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} ' +
        'alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  ' +
          '<i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" ' +
            'aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
      });
  }

  ngOnInit() {
    this.logout();
  }

  loginUser(event) {
    event.preventDefault();
    const target = event.target;
    const username = target.querySelector('#username').value;
    const password = target.querySelector('#password').value;
    this.Api.getUserDetails(username, password).subscribe(data => {
      const code = data.errorCode;
      if (code === 0) {
        this.showNotification('top', 'center', 'Ingresando al sistema', 1);
        this.router.navigateByUrl('dashboard');
      } else {
        this.showNotification('top', 'center', 'Usuario y/o Contraseña incorrectos', 1);
      }
      this.Api.guardarSesion(data.msg);
      // console.log(data);
      this.Api.guardarDatos(username);
    });
    // console.log(username, password);
  }

  logout() {
    sessionStorage.clear();
  }

}
