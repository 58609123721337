import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { Router, ActivatedRoute, } from '@angular/router';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    // { path: '/user-profile', title: 'User Profile',  icon: 'person', class: '' },
    // { path: '/table-list', title: 'Table List',  icon: 'content_paste', class: '' },
    // { path: '/typography', title: 'Typography',  icon: 'library_books', class: '' },
    // { path: '/icons', title: 'Icons',  icon: 'bubble_chart', class: '' },
    // { path: '/maps', title: 'Mapa',  icon: 'location_on', class: '' },
    // { path: '/notifications', title: 'Notifications',  icon: 'notifications', class: '' },
    { path: '/cliente', title: 'Cliente',  icon: 'perm_identity', class: 'cliente' },
    { path: '/venta', title: 'Venta',  icon: 'shopping_cart', class: 'venta' },
    { path: '/producto', title: 'Producto',  icon: 'local_offer', class: 'producto' },
    { path: '/productosucursal', title: 'Producto Sucursal',  icon: 'store_mall_directory', class: 'productosucursal' },
    { path: '/movimientoproducto', title: 'Movimiento de Producto',  icon: 'local_shipping', class: 'movimientoproducto' },
    { path: '/usuario', title: 'Usuario',  icon: 'person', class: 'usuario' },
    { path: '/tipousuario', title: 'Tipo de Usuario',  icon: 'person', class: 'tipousuario' },
    { path: '/tiposucursal', title: 'Tipo de Sucursal',  icon: 'store_mall_directory', class: 'tiposucursal' },
    { path: '/sucursal', title: 'Sucursal',  icon: 'store_mall_directory', class: 'sucursal' },
    { path: '/compra', title: 'Compra',  icon: 'store_mall_directory', class: 'compra' },
    { path: '/egreso', title: 'Egreso',  icon: 'local_atm', class: 'egreso' },
    { path: '/proveedor', title: 'Proveedor',  icon: 'store_mall_directory', class: 'proveedor' },
    // { path: '/upgrade', title: 'Upgrade to PRO',  icon: 'unarchive', class: 'active-pro' },
    // compare_arrows (para movimientoproducto)
];

export const DASHBOARD: RouteInfo[] = [
  { path: '/dashboard', title: 'Bienvenido',  icon: 'dashboard', class: '' },
  // { path: '/perfil', title: 'Perfil',  icon: 'account_box', class: 'perfil' },
];
export const TIPOENFERMEDAD: RouteInfo[] = [
  { path: '/tipoenfermedad', title: 'Tipo de Enfermedad',  icon: 'local_hospital', class: 'tipoenfermedad' },
];
export const ENFERMEDAD: RouteInfo[] = [
  { path: '/enfermedad', title: 'Enfermedad',  icon: 'local_hospital', class: 'enfermedad' },
];
export const PATOGENO: RouteInfo[] = [
  { path: '/patogeno', title: 'Patogeno',  icon: 'local_hospital', class: 'patogeno' },
];
export const TIPOPATOGENO: RouteInfo[] = [
  { path: '/tipopatogeno', title: 'Tipo de Patogeno',  icon: 'local_hospital', class: 'tipopatogeno' },
];
export const FASE: RouteInfo[] = [
  { path: '/fase', title: 'Fase',  icon: 'local_hospital', class: 'fase' },
];
export const BIOLOGIAPATOGENO: RouteInfo[] = [
  { path: '/biologiapatogeno', title: 'Biologia Patogeno',  icon: 'local_hospital', class: 'biologiapatogeno' },
];
export const EPIDEMIOLOGIA: RouteInfo[] = [
  { path: '/epidemiologia', title: 'Epidemiologia',  icon: 'local_hospital', class: 'epidemiologia' },
];
export const CONTACTO: RouteInfo[] = [
  { path: '/contacto', title: 'Contacto',  icon: 'account_box', class: 'contacto' },
];
export const PATROCINADOR: RouteInfo[] = [
  { path: '/patrocinador', title: 'Proveedor',  icon: 'work', class: 'patrocinador' },
];
export const PRODUCTO: RouteInfo[] = [
  { path: '/producto', title: 'Producto',  icon: 'local_offer', class: 'producto' }
];
export const NOTICIA: RouteInfo[] = [
  { path: '/noticia', title: 'Noticia',  icon: 'rate_review', class: 'noticia' }
];
export const USUARIOSISTEMA: RouteInfo[] = [
  { path: '/usuariosistema', title: 'Usuario de Sistema',  icon: 'person', class: 'usuariosistema' }
];
export const USUARIO: RouteInfo[] = [
  { path: '/usuario', title: 'Usuario Gastos',  icon: 'person', class: 'usuario' }
];
export const TIPOGASTO: RouteInfo[] = [
  { path: '/tipogasto', title: 'Tipo de Gasto',  icon: 'person', class: 'tipogasto' }
];
export const PROVEEDOR: RouteInfo[] = [
  { path: '/proveedor', title: 'Proveedor',  icon: 'person', class: 'proveedor' }
];
export const VEHICULO: RouteInfo[] = [
  { path: '/vehiculo', title: 'Vehiculo',  icon: 'person', class: 'vehiculo' }
];
export const FACTURA: RouteInfo[] = [
  { path: '/factura', title: 'Reporte Factura',  icon: 'person', class: 'factura' }
];
export const RECIBO: RouteInfo[] = [
  { path: '/recibo', title: 'Reporte Recibo',  icon: 'person', class: 'recibo' }
];
export const CONTROL: RouteInfo[] = [
  { path: '/control', title: 'Reporte Control',  icon: 'person', class: 'control' }
];
export const CULTIVO: RouteInfo[] = [
  { path: '/cultivo', title: 'Cultivo',  icon: 'person', class: 'control' }
];
export const COMPROBANTE: RouteInfo[] = [
  { path: '/comprobante', title: 'Comprobante',  icon: 'person', class: 'comprobante' }
];



export const CLIENTE: RouteInfo[] = [
  { path: '/cliente', title: 'Cliente',  icon: 'perm_identity', class: 'cliente' }
];
export const VENTA: RouteInfo[] = [
  { path: '/venta', title: 'Venta',  icon: 'shopping_cart', class: 'venta' }
];
export const PRODUCTOSUCURSAL: RouteInfo[] = [
  { path: '/productosucursal', title: 'Producto Sucursal',  icon: 'store_mall_directory', class: 'productosucursal' },
];
export const SUCURSAL: RouteInfo[] = [
  { path: '/sucursal', title: 'Sucursal',  icon: 'store_mall_directory', class: 'sucursal' }
];
export const ESTADOFECHA: RouteInfo[] = [
  { path: '/estadofecha', title: 'Estados por Fecha',  icon: 'store_mall_directory', class: 'estadofecha' }
];
export const PROFORMA: RouteInfo[] = [
  { path: '/proforma', title: 'Proforma',  icon: 'receipt', class: 'proforma' }
];
export const VENTAUSUARIO: RouteInfo[] = [
  { path: '/ventausuario', title: 'Ventas por Usuario',  icon: 'receipt', class: 'ventausuario' }
];
export const COMISIONUSUARIO: RouteInfo[] = [
  { path: '/comisionusuario', title: 'Comisiones por Usuario',  icon: 'receipt', class: 'Comisionusuario' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  dashboard: any;
  tipoenfermedad: any;
  enfermedad: any;
  patogeno: any;
  tipopatogeno: any;
  fase: any;
  biologiapatogeno: any;
  epidemiologia: any;
  contacto: any;
  patrocinador: any;
  producto: any;
  noticia: any;
  usuariosistema: any;
  usuario: any;
  tipogasto: any;
  proveedor: any;
  vehiculo: any;
  factura: any;
  recibo: any;
  control: any;
  cultivo: any;
  comprobante: any;

  clientes: any;
  ventas: any;
  productosSucursal: any;
  usuarios: any;
  sucursales: any;
  proformas: any;
  ventasUsuario: any;
  comisionesUsuario: any;
  sesion: any = {};
  permisos: any = {};
  ventasBool: any = 0;
  inventarioBool: any = 0;
  adminBool: any = 0;
  estadosFecha: any = {};
  per = 0;

  constructor(private Api: ApiService, private router: Router, private route: ActivatedRoute, ) { }

  ngOnInit() {
    this.sesion = this.Api.recuperarSesion();
    if ( this.sesion === null ) {
      this.router.navigateByUrl('login');
    }
    this.permisos = this.sesion.permisos;
    if (this.permisos.length === 10) {
      this.per = 2;
    }
    if (this.permisos.length === 7) {
      this.per = 3;
    }
    if (this.permisos.length === 18) {
      this.per = 1;
    }
    if (this.permisos.length === 3) {
      this.per = 4;
    }

    this.menuItems = ROUTES.filter(menuItem => menuItem);

    this.dashboard = DASHBOARD.filter(dash => dash);
    this.tipoenfermedad = TIPOENFERMEDAD.filter(tipoen => tipoen);
    this.enfermedad = ENFERMEDAD.filter(enfer => enfer);
    this.patogeno = PATOGENO.filter(pato => pato);
    this.tipopatogeno = TIPOPATOGENO.filter(tipopato => tipopato);
    this.fase = FASE.filter(fas => fas);
    this.biologiapatogeno = BIOLOGIAPATOGENO.filter(biopato => biopato);
    this.epidemiologia = EPIDEMIOLOGIA.filter(epi => epi);
    this.contacto = CONTACTO.filter(con => con);
    this.patrocinador = PATROCINADOR.filter(patro => patro);
    this.producto = PRODUCTO.filter(prod => prod);
    this.noticia = NOTICIA.filter(not => not);
    this.usuariosistema = USUARIOSISTEMA.filter(usu => usu);
    this.usuario = USUARIO.filter(usug => usug);
    this.tipogasto = TIPOGASTO.filter(tipog => tipog);
    this.proveedor = PROVEEDOR.filter(prov => prov);
    this.vehiculo = VEHICULO.filter(veh => veh);
    this.factura = FACTURA.filter(fac => fac);
    this.recibo = RECIBO.filter(rec => rec);
    this.control = CONTROL.filter(cont => cont);
    this.cultivo = CULTIVO.filter(cult => cult);
    this.comprobante = COMPROBANTE.filter(comp => comp);

    /*if (this.permisos[3].estado > 0) {
      this.clientes = CLIENTE.filter(cliente => cliente);
      this.ventasBool = 1;
    }
    if (this.permisos[4].estado > 0) {
      this.ventas = VENTA.filter(venta => venta);
      this.ventasBool = 1;
    }
    if (this.permisos[5].estado > 0) {
      this.productos = PRODUCTO.filter(producto => producto);
      this.inventarioBool = 1;
    }
    if (this.permisos[6].estado > 0) {
      this.productosSucursal = PRODUCTOSUCURSAL.filter(productoSucursal => productoSucursal);
      this.inventarioBool = 1;
    }
    if (this.permisos[0].estado > 0) {
      this.usuarios = USUARIO.filter(usuario => usuario);
      this.adminBool = 1;
    }
    if (this.permisos[8].estado > 0) {
      this.sucursales = SUCURSAL.filter(sucursal => sucursal);
      this.adminBool = 1;
    }
    if (this.permisos[12].estado > 0) {
      this.estadosFecha = ESTADOFECHA.filter(estadof => estadof);
    }*/
    this.proformas = PROFORMA.filter(proforma => proforma);
    this.ventasUsuario = VENTAUSUARIO.filter(ventaUsuario => ventaUsuario);
    this.comisionesUsuario = COMISIONUSUARIO.filter(comisionUsuario => comisionUsuario);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
